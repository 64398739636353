<template>
  <keep-alive>
    <component :is="component" v-if="component !== false" v-bind="$attrs" />
  </keep-alive>
</template>

<script>
import { get } from 'lodash'
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    fallback: {
      type: String,
      required: true
    }
  },
  computed: {
    component () {
      return get(this.$route.meta, `layout.components.${this.name}`, this.fallback)
    }
  }
}
</script>
